<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div class="columns">
            <div class="column">
              <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">{{ pageheading }}</span>
              </div>
              <div class="block">
                <form @submit.prevent="handleFormSubmit">
                  <div>

                    <div v-if="error !== null" class="has-text-danger">
                      {{ error }}
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Carrier Name
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.name"/>
                      </div>
                    </div>

                    <div class="field">
                      <label class="label is-size-7">
                        Short Name <small style="font-size: 10px;">(Maximum of 5 characters)</small>
                        <span class="has-text-danger" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input v-if="!carrier.code" class="input" type="text" name="name" v-model="input.code" maxlength="5" minlength="2"/>
                        <input v-if="carrier.code" class="input" type="text" name="name" :value="input.code"  maxlength="5" minlength="2" :disabled="carrier.code"/>
                      </div>
                      <small style="color: #eaae5e!important;">Short Name cannot be modified after saving the carrier.</small>
                    </div>
                  </div>
                  <div id="buttongroup" style="padding-top: 20px;">
                    <div class="field is-grouped">
                      <div class="control">
                        <button :disabled="!$hasPermissions(clientSession, ['CARRIERS'], 2)" type="submit" class="button is-accent has-text-white">Save</button>
                      </div>
                      <div class="control">
                        <a class="button is-light" v-on:click="$router.go(-1)">Cancel</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="column" style="max-width: 60%;">
              <!-- Reserved for future -->

              <!-- <pre v-html="menuItemJSON"></pre> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

let pageName = 'Carrier Details'

export default {
  components: {
    Loading
  },
  props: {
    carrier: {
      required: false,
      type: Object,
      default: function () {
        return {
          name: null,
          code: null
        }
      }
    }
  },
  async mounted () {
    if (this.carrier.code) {
      this.input = Object.assign({}, this.carrier)
    } else if (this.$route.params.code) {
      this.input = await this.getCarrier()
    } else {
      this.input = {
        name: null,
        code: null
      }
    }
  },
  data () {
    return {
      isLoading: false,
      pageheading: pageName,
      input: null,
      error: null
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  methods: {
    async getCarrier () {
      let response = await this.axios.get('/carriers/' + this.$route.params.code)

      return response.data
    },
    async handleFormSubmit () {
      let route = 'carriers'
      let response = null

      let request = {
        ...this.input
      }

      // console.log(this.carrier)

      if (this.carrier.code) {
        request.code = this.carrier.code
      }

      if (this.carrier.id) {
        request.id = this.carrier.id
      }

      if (!this.$route.query.code) {
        response = await this.axios.post(route, request)
      } else if (this.carrier.code !== undefined) {
        response = await this.axios.put(route + '/' + this.carrier.code, request)
      }

      if (response.status === 201 || response.status === 200) {
        this.$router.push({ name: 'CarrierManagementScreen' })
      } else {
        this.error = 'Unable to complete request. Error Code: CMES01'
      }
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
